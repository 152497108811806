.ant-drawer {
	&.ant-drawer-right {
		& .ant-drawer-content-wrapper {
			@apply bottom-3 right-3 top-3 rounded-xl;

			& .ant-drawer-content {
				@apply rounded-xl;
			}
		}
	}

	& .ant-drawer-header {
		@apply p-0;
	}

	& .ant-drawer-body {
		@apply p-4 pb-10 relative scrollbar-hidden;
	}

	&:not(:has(.ant-drawer-footer)) {
		& .ant-drawer-body {
			@apply pb-4;
		}
	}

	& .ant-drawer-mask {
		background-color: unset;
		background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.5) 100%);
	}
}

