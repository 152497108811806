.loader,
.loader:after {
	border-radius: 50%;
	width: 16px;
	height: 16px;
}
.loader {
	border-top: 3px solid rgba(255, 255, 255, 0.4);
	border-right: 3px solid rgba(255, 255, 255, 0.4);
	border-bottom: 3px solid rgba(255, 255, 255, 0.4);
	border-left: 3px solid #ffffff;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: spin 1.1s infinite linear;
	animation: spin 1.1s infinite linear;
}
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
.loader-wrapper {
	height: 100%;
}
.loader_lg {
	height: 100px;
	width: 100px;
	border-color: #cccccc;
	border-left-color: #111111;
}
.loader.success {
	border-top: 3px solid rgb(143, 216, 116);
	border-right: 3px solid rgb(143, 216, 116);
	border-bottom: 3px solid rgb(143, 216, 116);
	border-left: 3px solid #ffffff;
}

.outter-circle-spinner {
	width: 50px;
	height: 50px;
	margin: 0 auto;
	border: 3px solid #000000;
	border-radius: 50%;
	border-left-color: transparent;
	border-right-color: transparent;
	animation: circle-spinner infinite linear 2s;
}
@keyframes circle-spinner {
	0% {
		-webkit-transform: rotate(360deg);
	}
	100% {
		-webkit-transform: rotate(0deg);
	}
}

.dot-pulse {
	position: relative;
	left: -9999px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #000000;
	color: #000000;
	box-shadow: 9999px 0 0 -5px #000000;
	animation: dotPulse 1.5s infinite linear;
	animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
	content: "";
	display: inline-block;
	position: absolute;
	top: 0;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #000000;
	color: #000000;
}

.dot-pulse::before {
	box-shadow: 9984px 0 0 -5px #000000;
	animation: dotPulseBefore 1.5s infinite linear;
	animation-delay: 0s;
}

.dot-pulse::after {
	box-shadow: 10014px 0 0 -5px #000000;
	animation: dotPulseAfter 1.5s infinite linear;
	animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
	0% {
		box-shadow: 9984px 0 0 -5px #000000;
	}
	30% {
		box-shadow: 9984px 0 0 2px #000000;
	}
	60%,
	100% {
		box-shadow: 9984px 0 0 -5px #000000;
	}
}

@keyframes dotPulse {
	0% {
		box-shadow: 9999px 0 0 -5px #000000;
	}
	30% {
		box-shadow: 9999px 0 0 2px #000000;
	}
	60%,
	100% {
		box-shadow: 9999px 0 0 -5px #000000;
	}
}

@keyframes dotPulseAfter {
	0% {
		box-shadow: 10014px 0 0 -5px #000000;
	}
	30% {
		box-shadow: 10014px 0 0 2px #000000;
	}
	60%,
	100% {
		box-shadow: 10014px 0 0 -5px #000000;
	}
}

.spin {
	-webkit-animation: spin 800ms infinite linear;
	animation: spin 800ms infinite linear;
}
