.ant-breadcrumb {
	ol {
		display: flex;
		align-items: center;

		.ant-breadcrumb-link {
			cursor: pointer;
		}
	}
}
