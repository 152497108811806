.ant-layout-sider.smv-sidebar {
	&[data-isMobile="false"] {
		@apply sticky bottom-0 left-0 top-0 h-svh bg-slate-100 z-[999];
		width: 240px;

		.logo-container {
			@apply mx-4 mb-4 mt-4 flex max-h-16 justify-center;
			transition: width 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
		}

		&.minimized {
			.logo-container {
				max-width: 100%;
				@apply mx-2.5;

				.upload-logo {
					@apply truncate;

					.upload-logo-text {
						animation: hide 0.1s ease-in forwards;
					}
				}
			}

			ul {
				@apply px-3;

				li.ant-menu-item {
					padding-inline: 10px !important;
					margin-inline: auto !important;
					width: fit-content;

					.ant-menu-title-content {
						animation: hide 0.1s ease-in forwards;
					}
				}

				li.ant-menu-submenu {
					margin-inline: auto !important;

					.ant-menu-submenu-title {
						padding-inline: 10px !important;
						margin-inline: auto !important;
						width: fit-content;

						.ant-menu-title-content {
							animation: hide 0.1s ease-in forwards;
						}

						.ant-menu-submenu-arrow {
							animation: hide 0.1s ease-in forwards;
						}
					}
				}

				&.profile-dropdown li {
					padding-inline: 8px !important;
				}
			}
		}

		&:not(.minimized) {
			.profile-dropdown {
				@apply px-2.5;
			}
		}
	}
	&[data-isMobile="true"] {
		@apply fixed top-12 bottom-0 left-0 z-[999] bg-slate-100;

		&.minimized {
			@apply -translate-x-full;
		}
	}
}

@keyframes hide {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		display: none;
	}
}

