.ant-dropdown {
	.ant-dropdown-menu {
		.ant-dropdown-menu-item {
			@apply font-normal text-gray-500;

			&[data-item-red] {
				@apply text-red-500;
				&:hover {
					@apply bg-red-50;
				}
			}
		}
	}
}

@mixin menu-item {
	padding-left: 12px !important;
	@apply px-3 h-8 transition-all duration-200 ease-in-out flex items-center w-full;

	svg {
		@apply size-4 text-gray-500;
	}

	.ant-menu-title-content {
		@apply ms-3 text-gray-600 text-sm font-normal font-['Inter'] leading-tight;
	}

	&.ant-menu-item-selected {
		@apply ring-1 ring-gray-200 bg-white;

		svg {
			@apply text-primary-500;
		}

		.ant-menu-title-content {
			@apply text-primary-600;
		}
	}
}

.ant-menu.ant-menu-root.ant-menu-light {
	@apply bg-transparent border-none;

	li.ant-menu-item {
		@include menu-item;
	}

	.ant-menu-submenu {
		.ant-menu-submenu-title {
			@include menu-item;

			i.ant-menu-submenu-arrow {
				@apply text-gray-400;
			}
		}

		&.ant-menu-submenu-selected {
			.ant-menu-submenu-title {
				svg {
					@apply text-primary-500;
				}

				.ant-menu-title-content {
					@apply text-primary-600;
				}
			}
		}

		.ant-menu.ant-menu-sub {
			margin: 4px;
			@apply rounded-lg bg-transparent ms-7;

			li.ant-menu-item {
				@include menu-item;

				&:has(:not(svg)) {
					.ant-menu-title-content {
						@apply ms-0;
					}
				}
			}
		}
	}
}

