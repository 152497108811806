.ant-notification.ant-notification {
	&:has(.ant-notification-notice-wrapper)::before {
		width: 60vw;
		height: 320px;
		z-index: -1;
		content: "";
		position: absolute;
		top: -24px;
		transform: translateX(-50%);
		pointer-events: none;
		background: #fff;
		filter: blur(2px);
		-webkit-filter: blur(2px);
		-webkit-mask-image: radial-gradient(340px 250px ellipse at 50% -10px, #000, transparent 100%);
		position: absolute;
		background-size: 100%;
		background-position-y: 10px;
	}

	&.ant-notification-stack:not(.ant-notification-stack-expanded)
		> .ant-notification-notice-wrapper:nth-last-child(2) {
		& {
			@apply rounded-md border border-gray-500/40 bg-white bg-gradient-to-r from-gray-200 to-white  text-sm text-gray-600 shadow-sm  shadow-gray-200 [--tw-gradient-to-position:85%];
		}

		&:has(.ant-notification-notice-success) {
			@apply border-emerald-500/40 from-emerald-600/20 to-white text-emerald-600;
		}

		&:has(.ant-notification-notice-error) {
			@apply border-rose-500/40 from-rose-600/20 to-white text-rose-600;
		}

		&:has(.ant-notification-notice-warning) {
			@apply border-amber-500/40 from-yellow-600/20 to-white text-amber-600;
		}

		&:has(.ant-notification-notice-info) {
			@apply border-blue-500/40 from-blue-600/20 to-white text-blue-600;
		}

		&.ant-notification-notice-wrapper {
			border-radius: 12px;
			border: none;
			border-top: 1px solid rgba(255, 255, 255, 0.32);
			background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0) 100%),
				var(--gray-800, #1f2937);
			box-shadow:
				0px 12px 24px 0px rgba(0, 0, 0, 0.24),
				0px 0px 0px 1px rgba(255, 255, 255, 0.16) inset,
				0px 2px 4px 0px rgba(17, 24, 39, 0.11),
				0px 1px 1px 0px rgba(17, 24, 39, 0.12),
				0px 0px 0px 1px #000;
			color: #fff;
		}
	}
	&.ant-notification-stack:not(.ant-notification-stack-expanded)
		> .ant-notification-notice-wrapper:nth-last-child(3) {
		& {
			@apply rounded-md border border-gray-500/40 bg-white bg-gradient-to-r from-gray-200 to-white  text-sm text-gray-600 shadow-sm  shadow-gray-200 [--tw-gradient-to-position:85%];
		}

		&:has(.ant-notification-notice-success) {
			@apply border-emerald-500/40 from-emerald-600/20 to-white text-emerald-600;
		}

		&:has(.ant-notification-notice-error) {
			@apply border-rose-500/40 from-rose-600/20 to-white text-rose-600;
		}

		&:has(.ant-notification-notice-warning) {
			@apply border-amber-500/40 from-yellow-600/20 to-white text-amber-600;
		}

		&:has(.ant-notification-notice-info) {
			@apply border-blue-500/40 from-blue-600/20 to-white text-blue-600;
		}

		&.ant-notification-notice-wrapper {
			border-radius: 12px;
			border: none;
			border-top: 1px solid rgba(255, 255, 255, 0.32);
			background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0) 100%),
				var(--gray-800, #1f2937);
			box-shadow:
				0px 12px 24px 0px rgba(0, 0, 0, 0.24),
				0px 0px 0px 1px rgba(255, 255, 255, 0.16) inset,
				0px 2px 4px 0px rgba(17, 24, 39, 0.11),
				0px 1px 1px 0px rgba(17, 24, 39, 0.12),
				0px 0px 0px 1px #000;
			color: #fff;
		}
	}

	.ant-notification-notice-wrapper {
		transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;

		& {
			@apply rounded-md border border-gray-500/40 bg-gradient-to-r from-gray-200 to-white  text-sm text-gray-600 shadow-sm  shadow-gray-200 [--tw-gradient-to-position:85%];
		}

		&:has(.ant-notification-notice-success) {
			@apply border-emerald-500/40 from-emerald-600/20 to-white text-emerald-600;
		}

		&:has(.ant-notification-notice-error) {
			@apply border-rose-500/40 from-rose-600/20 to-white text-rose-600;
		}

		&:has(.ant-notification-notice-warning) {
			@apply border-amber-500/40 from-yellow-600/20 to-white text-amber-600;
		}

		&:has(.ant-notification-notice-info) {
			@apply border-blue-500/40 from-blue-600/20 to-white text-blue-600;
		}

		&.ant-notification-notice-wrapper {
			border-radius: 12px;
			border: none;
			border-top: 1px solid rgba(255, 255, 255, 0.32);
			background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0) 100%),
				var(--gray-800, #1f2937);
			box-shadow:
				0px 12px 24px 0px rgba(0, 0, 0, 0.24),
				0px 0px 0px 1px rgba(255, 255, 255, 0.16) inset,
				0px 2px 4px 0px rgba(17, 24, 39, 0.11),
				0px 1px 1px 0px rgba(17, 24, 39, 0.12),
				0px 0px 0px 1px #000;
			color: #fff;
		}

		.ant-notification-notice.ant-notification-notice {
			@apply p-2.5 opacity-100 pb-3 min-w-[380px];

			&.ant-notification-notice-closable {
				@apply pr-8;
			}

			.ant-notification-notice-content {
				@apply block text-left;

				& > div {
				}
				.ant-notification-notice-icon {
					@apply left-3 top-3 inline-flex;
					> svg {
						@apply size-4;
					}
				}

				.ant-notification-notice-message {
					@apply mb-0 ml-7 text-sm text-current;
				}
				.ant-notification-notice-description {
					@apply ml-7 mt-1 text-xs text-slate-300;
				}
			}

			.ant-notification-notice-close {
				top: 8px;
				right: 8px;
				@apply transition-colors;

				&:hover {
					@apply ring-1 ring-gray-300/20;
				}

				& svg {
					@apply size-3 text-gray-400;
				}
			}
		}
	}
}

